/**
 * Modifies the muiTheme with the options from customTheme
 *
 * @param {object} muiTheme
 * @param {object} customTheme
 */

import FabricStyle1 from "./textures/fabric-style-1.png";
import MetalStyle1 from "./textures/metal-style-1.png";
import PatternStyle1 from "./textures/pattern-style-1.png";
import StoneStyle1 from "./textures/stone-style-1.png";
import WoodStyle1 from "./textures/wood-style-1.png";
import FabricStyle2 from "./textures/fabric-style-2.png";
import PatternStyle2 from "./textures/pattern-style-2.png";
import StoneStyle2 from "./textures/stone-style-2.png";
import WoodStyle2 from "./textures/wood-style-2.png";
import FabricStyle3 from "./textures/fabric-style-3.png";
import PatternStyle3 from "./textures/pattern-style-3.png";
import StoneStyle3 from "./textures/stone-style-3.png";
import WoodStyle3 from "./textures/wood-style-3.png";

export const modifyMui = (muiTheme, customTheme) => {
  const t = customTheme.ThemeOptions;
  muiTheme.customTheme = t;

  // Fonts
  const bodyFont = "Overpass";
  muiTheme.bodyFont = "Overpass";
  const headerFont = t.fonts.headerFont;
  muiTheme.headerFont = t.fonts.headerFont;
  // const subHeaderFont = "Roboto Condensed";
  muiTheme.subHeaderFont = "Roboto Condensed";

  const themeTexture = t.themeTexture.themeTextureMain;

  muiTheme.overrides.MuiCssBaseline = {
    "@global": {
      body: {
        fontFamily: bodyFont,
      },
      "h1, h2, h3, h4, h5, h6": {
        fontFamily: headerFont,
      },
    },
  };
  muiTheme.typography = {
    fontFamily: bodyFont,
    useNextVariants: true,
  };

  // Colours
  muiTheme.palette = {
    primary: {
      main: t.mainColours.primaryColour,
      light: shadeBlend(0.1, t.mainColours.primaryColour),
      dark: shadeBlend(-0.1, t.mainColours.primaryColour),
      contrastText: t.foregroundColours.foregroundPrimaryColour,
    },
    secondary: {
      main: t.mainColours.secondaryColour,
      light: shadeBlend(0.1, t.mainColours.secondaryColour),
      dark: shadeBlend(-0.1, t.mainColours.secondaryColour),
      contrastText: t.foregroundColours.foregroundSecondaryColour,
    },
    tertiary: {
      main: t.mainColours.tertiaryColour,
      light: shadeBlend(0.1, t.mainColours.tertiaryColour),
      dark: shadeBlend(-0.1, t.mainColours.tertiaryColour),
      contrastText: t.foregroundColours.foregroundfertiaryColour,
    },
    text: {
      primary: t.generalColours.black,
      secondary: t.generalColours.black,
    },
    background: {
      paper: t.generalColours.white,
      default: t.generalColours.bodyBackground,
      primary: t.backgroundColours.backgroundPrimaryColour,
      secondary: t.backgroundColours.backgroundSecondaryColour,
    },
  };

  if (themeTexture === "fabric") {
    if (t.themeTexture.fabricTextureStyle === "-style-1") {
      muiTheme.texture = `url(${FabricStyle1})`;
    } else if (t.themeTexture.fabricTextureStyle === "-style-2") {
      muiTheme.texture = `url(${FabricStyle2})`;
    } else if (t.themeTexture.fabricTextureStyle === "-style-3") {
      muiTheme.texture = `url(${FabricStyle3})`;
    }
  } else if (themeTexture === "metal") {
    if (t.themeTexture.metalTextureStyle === "-style-1") {
      muiTheme.texture = `url(${MetalStyle1})`;
    }
  } else if (themeTexture === "pattern") {
    if (t.themeTexture.patternTextureStyle === "-style-1") {
      muiTheme.texture = `url(${PatternStyle1})`;
    } else if (t.themeTexture.patternTextureStyle === "-style-2") {
      muiTheme.texture = `url(${PatternStyle2})`;
    } else if (t.themeTexture.patternTextureStyle === "-style-3") {
      muiTheme.texture = `url(${PatternStyle3})`;
    }
  } else if (themeTexture === "stone") {
    if (t.themeTexture.stoneTextureStyle === "-style-1") {
      muiTheme.texture = `url(${StoneStyle1})`;
    } else if (t.themeTexture.stoneTextureStyle === "-style-2") {
      muiTheme.texture = `url(${StoneStyle2})`;
    } else if (t.themeTexture.stoneTextureStyle === "-style-3") {
      muiTheme.texture = `url(${StoneStyle3})`;
    }
  } else if (themeTexture === "wood") {
    if (t.themeTexture.woodTextureStyle === "-style-1") {
      muiTheme.texture = `url(${WoodStyle1})`;
    } else if (t.themeTexture.woodTextureStyle === "-style-2") {
      muiTheme.texture = `url(${WoodStyle2})`;
    } else if (t.themeTexture.woodTextureStyle === "-style-3") {
      muiTheme.texture = `url(${WoodStyle3})`;
    }
  }

  muiTheme.common = {
    black: t.generalColours.black,
    white: t.generalColours.white,
  };

  return muiTheme;
};

function shadeBlend(p, c0, c1) {
  var n = p < 0 ? p * -1 : p,
    u = Math.round,
    w = parseInt;
  if (c0.length > 7) {
    var f = c0.split(","),
      t = (c1 ? c1 : p < 0 ? "rgb(0,0,0)" : "rgb(255,255,255)").split(","),
      R = w(f[0].slice(4)),
      G = w(f[1]),
      B = w(f[2]); // eslint-disable-line
    return (
      "rgb(" +
      (u((w(t[0].slice(4)) - R) * n) + R) +
      "," +
      (u((w(t[1]) - G) * n) + G) +
      "," +
      (u((w(t[2]) - B) * n) + B) +
      ")"
    ); // eslint-disable-line
  } else {
    var f = w(c0.slice(1), 16), // eslint-disable-line
      t = w((c1 ? c1 : p < 0 ? "#000000" : "#FFFFFF").slice(1), 16), // eslint-disable-line
      R1 = f >> 16, // eslint-disable-line
      G1 = (f >> 8) & 0x00ff, // eslint-disable-line
      B1 = f & 0x0000ff; // eslint-disable-line
    return (
      "#" +
      (
        0x1000000 +
        (u(((t >> 16) - R1) * n) + R1) * 0x10000 +
        (u((((t >> 8) & 0x00ff) - G1) * n) + G1) * 0x100 +
        (u(((t & 0x0000ff) - B1) * n) + B1)
      )
        .toString(16)
        .slice(1)
    ); // eslint-disable-line
  }
}
