import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import themeData from "./muiTheme.json";
import themeOptions from "../../../.brew-cache/theme.json";
import {modifyMui} from "../modifyMui";

const themeName = 'Brew Core Theme';
const modifiedTheme = modifyMui(themeData, themeOptions);
let theme = createMuiTheme({ ...modifiedTheme, themeName });
theme = responsiveFontSizes(theme, { factor: 3 });

// Custom theme styles here or import them from wherever if they need to be broken

theme.headerTheme = {
    '&.MuiTypography-h1': {
        fontSize: 48,
        fontWeight: '400',
        letterSpacing: '-0.016em',
        lineHeight: 1.125,
        fontFamily: theme.headerFont,
        [theme.breakpoints.up('md')]: {
            fontSize: 80,
            lineHeight: 1.125,
        }
    },
    '&.MuiTypography-h2': {
        fontSize: 32,
        letterSpacing: '-0.008em',
        lineHeight: 1.5,
        fontWeight: '400',
        fontFamily: theme.headerFont,
        [theme.breakpoints.up('md')]: {
            fontSize: 56,
            lineHeight: 1.23,
        }
    },
    '&.MuiTypography-h3': {
        fontSize: 28,
        lineHeight: 2.28,
        fontWeight: '900',
        fontFamily: theme.bodyFont,
        [theme.breakpoints.up('md')]: {
            fontSize: 48,
            lineHeight: 1.33,
            fontFamily: theme.subHeaderFont,
        }
    },
    '&.MuiTypography-h4': {
        fontSize: 20,
        lineHeight: 2.4,
        fontWeight: '700',
        letterSpacing: '0.008em',
        fontFamily: theme.bodyFont,
        [theme.breakpoints.up('md')]: {
            fontSize: 32,
            lineHeight: 1.5,
            fontWeight: 400,
            fontFamily: theme.subHeaderFont,
        }
    },
    '&.MuiTypography-h5': {
        fontSize: 18,
        lineHeight: 2.2,
        letterSpacing: '0.016em',
        fontFamily: theme.bodyFont,
        fontWeight: 900,
        [theme.breakpoints.up('md')]: {
            fontSize: 24,
            lineHeight: 1.66,
            fontWeight: 400,
            fontFamily: theme.subHeaderFont,
        }
    },
    '&.MuiTypography-h6': {
        fontSize: 16,
        fontWeight: '700',
        lineHeight: 1.5,
        letterSpacing: '0.016em',
        fontFamily: theme.headerFont,
        [theme.breakpoints.up('md')]: {
            fontSize: 16,
        }
    },
}

export default theme;