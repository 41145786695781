module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"disableAutoprefixing":false,"disableMinification":false,"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../plugins/custom-themes/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://workshop-pro.brew-systems.co.uk/graphql","schema":{"perPage":20,"timeout":300000,"requestConcurrency":5,"previewRequestConcurrency":2,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"excludeFieldNames":["comments","blocksJSON","previewBlocks","previewBlocksJSON"],"verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"ga_code":""}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"26","matomoUrl":"https://allianceautomotive.matomo.cloud","siteUrl":"https://www.workshop-pro.co.uk","requireCookieConsent":true,"trackLoad":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
