// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-brands-wp-brand-slug-js": () => import("./../../../src/pages/brands/{WpBrand.slug}.js" /* webpackChunkName: "component---src-pages-brands-wp-brand-slug-js" */),
  "component---src-pages-course-enquiry-js": () => import("./../../../src/pages/course-enquiry.js" /* webpackChunkName: "component---src-pages-course-enquiry-js" */),
  "component---src-pages-course-provider-wp-course-provider-slug-js": () => import("./../../../src/pages/course-provider/{WpCourseProvider.slug}.js" /* webpackChunkName: "component---src-pages-course-provider-wp-course-provider-slug-js" */),
  "component---src-pages-distributors-js": () => import("./../../../src/pages/distributors.js" /* webpackChunkName: "component---src-pages-distributors-js" */),
  "component---src-pages-edit-js": () => import("./../../../src/pages/edit.js" /* webpackChunkName: "component---src-pages-edit-js" */),
  "component---src-pages-enquiry-js": () => import("./../../../src/pages/enquiry.js" /* webpackChunkName: "component---src-pages-enquiry-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-preview-page-js": () => import("./../../../src/pages/preview-page.js" /* webpackChunkName: "component---src-pages-preview-page-js" */),
  "component---src-pages-preview-post-js": () => import("./../../../src/pages/preview-post.js" /* webpackChunkName: "component---src-pages-preview-post-js" */),
  "component---src-pages-privacy-notice-js": () => import("./../../../src/pages/privacy-notice.js" /* webpackChunkName: "component---src-pages-privacy-notice-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-wish-list-js": () => import("./../../../src/pages/wish-list.js" /* webpackChunkName: "component---src-pages-wish-list-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-course-js": () => import("./../../../src/templates/course.js" /* webpackChunkName: "component---src-templates-course-js" */),
  "component---src-templates-list-posts-js": () => import("./../../../src/templates/list-posts.js" /* webpackChunkName: "component---src-templates-list-posts-js" */),
  "component---src-templates-location-js": () => import("./../../../src/templates/location.js" /* webpackChunkName: "component---src-templates-location-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

